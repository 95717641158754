import './index.scss';

import { Outlet } from '@modern-js/runtime/router';
import { Layout, Alert, Dropdown } from '@arco-design/web-react';
import { IconArrowLineRight, IconFile, IconHkCopilot, IconQuestionCircle, IconVideoPlay } from '@arco-iconbox/react-hkgai-government-icon';
import { useModel } from '@modern-js/runtime/model';
import { useContext, useLayoutEffect, useMemo, useState } from 'react';
import { AuthUserInfo } from '../auth';
import { GlobalContext } from '../context';
import MultiLanguageDropdown from '@/components/language-dropdown';
import useLocale from '@/hooks/useLocale';
import { getNotification } from '@/api/copilot_api';
import { Notification, SidebarStatus, SidebarType } from '@/type/copilot';
import GlobalStore from '@/store/globalStore';

const { Header, Content } = Layout;

const PageLayout = () => {
  const locale = useLocale();
  const { lang } = useContext(GlobalContext);
  const [{ sideBar }, { fetchSideBar }] = useModel(GlobalStore);

  const [notification, setNotification] = useState<Notification>();

  useLayoutEffect(() => {
    fetchNotification();
    fetchSideBar();
  }, []);

  const [isShowDropdown, isUsageTutorial, isChangeLog] = useMemo(() => {
    const dropdownConfig = sideBar?.find(item => item.type === SidebarType.HK_CHAT);
    const usageTutorialConfig = sideBar?.find(item => item.type === (102 as SidebarType));
    const changeLogConfig = sideBar?.find(item => item.type === (103 as SidebarType));

    return [dropdownConfig?.status === SidebarStatus.ENABLED, usageTutorialConfig?.status === SidebarStatus.ENABLED, changeLogConfig?.status === SidebarStatus.ENABLED];
  }, [sideBar?.length]);

  const fetchNotification = async () => {
    try {
      const data = await getNotification({});
      console.log('fetchNotification data', data);

      setNotification(() => data.notification || {});
    } catch (error) {}
  };
  const notificationVal = useMemo(() => {
    switch (lang) {
      case 'zh-CN':
        return notification?.contentZh ? notification?.contentZh : notification?.contentEn || '';
      case 'en-US':
        return notification?.contentEn || '';
      case 'zh-HK':
        return notification?.contentZhHk ? notification.contentZhHk : notification?.contentEn || '';
      default:
        return notification?.contentEn || '';
    }
  }, [notification, lang]);

  const urlMap: any = {
    faq: {
      'zh-CN': 'https://hkgpt-application.sg.larksuite.com/docx/Nxb7dxqxIoM2G5xSV9tlnDDmghd',
      'en-US': 'https://hkgpt-application.sg.larksuite.com/docx/UichdAOCOoku1HxuYvsleEgdgYd',
      'zh-HK': 'https://hkgpt-application.sg.larksuite.com/docx/Az8td1ky1ovAvqxU88MlSf5Ugsf',
    },
    usageTutorialUrl: {
      'zh-CN': 'https://hkgpt-application.sg.larksuite.com/docx/CBeKdHXEHoJwR7xtqLmlbXq3gmb',
      'en-US': 'https://hkgpt-application.sg.larksuite.com/docx/Okv6dIa4eoDaUXx9HB6l47kCgGe',
      'zh-HK': 'https://hkgpt-application.sg.larksuite.com/docx/P55hd8tbAoCzbHxkYcsltTZEgTf',
    },
  };

  const DropdownList = (
    <div className="head-dropdown-list">
      <div
        className="head-dropdown-menu-item"
        onClick={() => {
          window.open('https://chat.hkgai.net', '_blank');
        }}
      >
        Explore HKChat
      </div>
    </div>
  );

  //   const DropdownNotificati = useMemo(() => {
  //     return (
  //       <div className="notificati-dropdown">
  //         <IconEmpty style={{ width: '180px', height: '110.19px' }} />
  //         <div>{locale.layout_head_notification_dropdown}</div>
  //       </div>
  //     );
  //   }, [locale]);

  return (
    <>
      <Layout className="h-screen flex flex-col page-ai-bg overflow-hidden">
        <Header className="bg-white custom-header">
          <div className="layout-head">
            {isShowDropdown ? (
              <Dropdown droplist={DropdownList} trigger="click">
                <div className="icon-copilot-wrap">
                  <IconHkCopilot style={{ width: '129px', height: '36px', cursor: 'pointer' }} />
                  <IconArrowLineRight />
                </div>
              </Dropdown>
            ) : (
              <div className="icon-copilot-wrap">
                <IconHkCopilot style={{ width: '129px', height: '36px', cursor: 'pointer' }} />
              </div>
            )}
            <div className="layout-head-actions">
              {isUsageTutorial ? (
                <div
                  className="layout-head-actions__button"
                  onClick={() => {
                    window.open(urlMap.usageTutorialUrl[(lang as string) || 'en-US'], '_black');
                  }}
                >
                  <IconVideoPlay />
                  <div>{locale.layout_head_actions_guidelines}</div>
                </div>
              ) : (
                <div></div>
              )}

              <div className="layout-head-content">
                {isChangeLog && (
                  <div
                    className="layout-head-actions__button"
                    onClick={() => {
                      window.open('https://hkgpt-application.sg.larksuite.com/docx/Qre0dlbw8obzJ9xNjKDlTuBHgVg', '_black');
                    }}
                  >
                    <IconFile />
                    <div>{locale.layout_head_actions_change_log}</div>
                  </div>
                )}

                <div
                  className="layout-head-actions__button"
                  onClick={() => {
                    window.open(urlMap.faq[(lang as string) || 'en-US'], '_black');
                  }}
                >
                  <IconQuestionCircle />
                  <div>FAQ</div>
                </div>

                {/* <Dropdown droplist={DropdownNotificati} position="br">
                  <div className="layout-head-actions__button">
                    <IconNotificationIcon />
                    <div>{locale.layout_head_actions_notification}</div>
                  </div>
                </Dropdown> */}
              </div>
            </div>
            <div className="layout-head-extra">
              <MultiLanguageDropdown />
              <AuthUserInfo />
            </div>
          </div>
          <div className="bg-[#F2F2F2] h-2"></div>
        </Header>
        <Content style={{ background: '#F2F2F2', padding: 0 }}>
          <Outlet />
        </Content>
      </Layout>

      <div className="alert-warning-wrap" style={{ display: notificationVal?.length > 0 ? 'block' : 'none' }}>
        <Alert type="warning" content={notificationVal} closable />
      </div>
    </>
  );
};
export default PageLayout;
